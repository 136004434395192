import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private http: HttpClient) { }

  //Postman -> Country&State&City -> Get Cites By StateId 
  getCityByStateId(stateId) {
    const url = environment.apiUrl + "states/" + stateId + "/state_city";
    return this.http.get(url);
  }

}
