import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  constructor(private http: HttpClient) { }

  //Postman -> Sponsor, Site and CRO CRUD -> createSponsor
  createSponsor(sponsorDetails) {
    const url = environment.apiUrl + "sponsors";
    return this.http.post(url, sponsorDetails);
  }

  //Postman -> Sponsor, Site and CRO CRUD -> Get Sponsor
  getSponsorsList() {
    const header = new HttpHeaders()
      .set('filter', `{"include":["sponsorCountry","sponsorState","sponsorCity"]}`);
    const url = environment.apiUrl + "sponsors";
    return this.http.get(url, { headers: header });
  }
  getSponsorsListforcountryList(countryArray) {
    console.log(countryArray)
    const header = new HttpHeaders()
      .set('filter', `{"where":{"or":${JSON.stringify(countryArray)}},"include":["sponsorCountry","sponsorState","sponsorCity"]}`);
    const url = environment.apiUrl + "sponsors";
    return this.http.get(url, { headers: header });
  }
  getSponsorsListforstatus(status) {
    console.log(status)
    const header = new HttpHeaders()
      .set('filter', `{"where":{"status":${status}},"include":["sponsorCountry","sponsorState","sponsorCity"]}`);
    const url = environment.apiUrl + "sponsors";
    return this.http.get(url, { headers: header });
  }

  //Postman -> Sponsor -> Get All Sponsor without Assigned Sponsor
  getSponsorsListWithOutPrimarySponsor(sponsorArray) {
    let headerReq;
    const header = new HttpHeaders()
      .set('filter', `{"where":{"and":${JSON.stringify(sponsorArray)}},"include":["sponsorCountry","sponsorState","sponsorCity"]}`);
      const header2 = new HttpHeaders()
      .set('filter', `{"include":["sponsorCountry","sponsorState","sponsorCity"]}`);
      if (sponsorArray.length !== 0) {
        headerReq = header;
      } else {
        headerReq = header2;
      }
    const url = environment.apiUrl + "sponsors";
    return this.http.get(url, { headers: headerReq });
  }
 
  //Postman -> Sponsor, Site and CRO CRUD -> Get Sponsor Details By SponsorId
  getSposnsorDetailsBySponsorId(sponsorId) {
    const url = environment.apiUrl + "sponsors/" + sponsorId;
    return this.http.get(url);
  }

  //Postman -> Sponsor, Site and CRO CRUD -> Update Sponsor Details
  updateSponsor(sponsorDetails) {
    const url = environment.apiUrl + "sponsors";
    return this.http.patch(url, sponsorDetails);
  }

}
